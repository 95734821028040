<template>
    <div v-if="value.length" class="card">
        <GChart type="Sankey" :settings="settings" :data="chartData" :options="options" @ready="onChartReady" />
    </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
    name: "SankeyDiagram",
    props: {
        value: Array
    },
    data() {
        return {
            settings: {
                packages: ["sankey"]
            },
            options: {
                height: 700,
                sankey: {
                    node: {
                        interactivity: true
                    },
                    link: {
                        interactivity: true,
                        color: { stroke: '#FFF', strokeWidth: 1 }
                    }
                },
                tooltip: { isHtml: true }
            },
            chart:  null,
            google: null,
        };
    },
    methods: {
        onChartReady(chart, google) {
            this.chart = chart;
            this.google = google;
        }
    },
    computed: {
        chartData() {
            let t_data = [];
            let makePopup = (movement) => {
                return `<div class="card">
                            <div>Source : <b>${movement.source}</b></div>
                            <div>Quantity: <b>${movement.quantity} ${movement.uom || ''}</b></div>
                            <div>Destination : <b>${movement.destination}</b></div>
                        </div>`
            }
            if (this.value.length > 0) {
                t_data = this.value.map((o) => {
                    return [o.source, o.destination, o.quantity, makePopup(o)];
                });
            }
            t_data.unshift(['Source' , 'Destination' , 'Quantity', { type: "string", role: "tooltip", p: { html: true } }]);
            return t_data;
        },
    },
    components: {
        GChart
    }
};
</script>

<style scoped>
</style>