<template>
    <div>
        <Toast />
        <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-fluid p-grid p-formgrid">
                            <div class="p-field p-col-4 p-md-4">
                                <label for="cal">{{$t('Location Filter')}}</label>
                                <MultiSelect id="location" v-model="selected_locations" :options="locations" display="chip" @change="filter()" />
                            </div>
                            <div class="p-field p-col-4 p-md-4">
                                <label for="cal">{{$t('Country Filter')}}</label>
                                <MultiSelect id="country" v-model="selected_countries" :options="countries" display="chip" @change="filter()"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <SankeyDiagram  v-model="location_movement" />
                    </div>
                    <div class="p-col-6">
                        <SankeyDiagram  v-model="country_movement" />
                    </div>
            </div>
        </div>
</template>

<script>
import SankeyDiagram from "../components/SankeyDiagram.vue";
import ApiService from '../service/ApiService';

export default {
    name: "ScanLocation",
    components: {
        SankeyDiagram
    },
    data: () => ({
        all_movements: [],
        location_movement: [],
        country_movement: [],
        dates: null,
        countries: null,
        locations: null,
        selected_countries: [],
        selected_locations: []
    }),
    created() {
        this.api = new ApiService();
        this.api.getFromEp('reports', {name: 'stock_movement'}).then(result => {
            const data = result.filter(r => {
                return this.roleToCountry().includes(this.dpCountryConvert(r.destinationCountry)) || this.roleToCountry().includes(this.dpCountryConvert(r.sourceCountry));
            });
            this.all_movements = data;
            this.countries = [... new Set(data.flatMap(r => [r.sourceCountry, r.destinationCountry]))];
            this.locations = [... new Set(data.flatMap(r => [r.sourceLocation, r.destinationLocation]))];
            this.filter();
        });
    },
    destroyed() {
    },
    methods: {
        dpCountryConvert(c) {
            const cc = {
                'Brazil': 'brazil',
                'Canada': 'canada',
                'United States': 'usa',
                'United Kingdom': 'uk',
                'india': 'india',
                'uganda': 'uganda',
                'namibia': 'namibia',
                'congo': 'drc',
                'zambia': 'zambia',
                'rwanda': 'rwanda'
            }
            return cc[c];
        },
        roleToCountry(){
            const countries = [];
            if (this.$auth.user.allRoles.includes('dashboard-country-rw')) countries.push('rwanda');
            if (this.$auth.user.allRoles.includes('dashboard-country-na')) countries.push('namibia');
            if (this.$auth.user.allRoles.includes('dashboard-country-drc')) countries.push('drc');
            if (this.$auth.user.allRoles.includes('dashboard-country-za')) countries.push('zambia');
            if (this.$auth.user.allRoles.includes('dashboard-country-ug')) countries.push('uganda');
            if (this.$auth.user.allRoles.includes('dashboard-country-ca')) countries.push('canada');
            if (this.$auth.user.allRoles.includes('dashboard-country-usa')) countries.push('usa');
            if (this.$auth.user.allRoles.includes('dashboard-country-br')) countries.push('brazil');
            if (this.$auth.user.allRoles.includes('dashboard-country-in')) countries.push('india');
            return countries;
        },
        filter() {
            this.location_movement = this.all_movements.filter(r => {
                if (this.selected_locations.length) {
                    return this.selected_locations.includes(r.sourceLocation) || this.selected_locations.includes(r.destinationLocation);
                }
                if (this.selected_countries.length) {
                    return this.selected_countries.includes(r.sourceCountry) || this.selected_countries.includes(r.destinationCountry);
                }
                return true;
            }).map(m => {
                return {
                    source: m.sourceLocation,
                    destination: m.destinationLocation,
                    quantity: m.quantity,
                    uom: 'Units'
                }
            });
            this.country_movement = this.all_movements.filter(r => {
                if (this.selected_locations.length) {
                    return this.selected_locations.includes(r.sourceLocation) || this.selected_locations.includes(r.destinationLocation);
                }
                if (this.selected_countries.length) {
                    return this.selected_countries.includes(r.sourceCountry) || this.selected_countries.includes(r.destinationCountry);
                }
                return true;
            }).map(m => {
                return {
                    source: m.sourceCountry,
                    destination: m.destinationCountry,
                    quantity: m.quantity,
                    uom: 'Units'
                }
            });
        }
    }
};
</script>


<style lang="scss" scoped>
.full-webview {
    /* view-height minus 70 pixel top padding, minus 2rem bottom padding, minus an extra 5px */
    height: calc(100vh - 75px - 2rem);
    width: 100%;
}

</style>
